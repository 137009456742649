import React, { Component } from "react";
import './Footer.css';
import { Link } from "react-router-dom";
// Dans votre fichier JavaScript (par exemple, index.js)
import '@fortawesome/fontawesome-free/css/all.css';


class Footer extends Component {
    render() {
        return (
            <footer id={this.props.id}>
                <div id="hautFooter">
                    <div id="zoneGauche">
                        <h3 className="titreFooter">Liens utiles</h3>
                        <Link to="/actualités" className='Compartment lien' id='actualités'>
                            Actualités
                        </Link>
                        <br />


                        <Link to='/activites' className='Compartment lien' id='activités'>
                            Activités du Festival
                        </Link>
                        <br />


                        <Link to='/talents' className='Compartment lien' id='talents'>
                            Meilleurs Talents
                        </Link>
                        <br />


                        <Link to="/AproposDeNous" className='Compartment lien' id='nous'>
                            Qui sommes nous?
                        </Link>
                        <br />

                        <Link className='Compartment lien' id='NosPartenaires'>
                            Nos partenaires
                        </Link>
                        <br />

                    </div>

                    <div id="zoneCentre">
                        <h3 className="titreFooter">Localisation</h3>
                        <p>Retrouvez-nous</p><br />
                        <p>Siège social: Soa-Yaounde </p><br />
                        <p> Village artistique et culturel : Musée National</p><br />
                        <p>Ville : Yaoundé, Cameroun</p>
                        <p>Créé le 09 Mars 2013 à Ydé par la CERFESAC </p>
                        
                    </div>
                    <div id="zoneDroite">
                        <h3 className="titreFooter">Contacts</h3>
                        <p>Contactez nous</p><br />
                        <p>BP : 5281 Yaounde</p><br />
                        <p>(+237) 698203629</p><br />
                        <p>(+237) 242947169</p><br />
                        <a href="https://www.facebook.com/profile.php?id=100067769438879&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook" style={{ fontSize: '24px', color: 'blue', margin: '0px 5px' }}></i>
                    </a>

                    <a href="https://youtube.com/@fairarts-ho3wo?si=401JcqUH_8CA8w2n" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube" style={{ fontSize: '24px', color: 'red', margin: '0px 5px' }}></i>
                    </a>

                    <a href="https://www.instagram.com/cerfesac?igsh=YzljYTk1ODg3Zg==" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram" style={{ fontSize: '24px', color: 'purple', margin: '0px 5px' }}></i>
                    </a>

                    <a href="https://wa.me/qr/CTCBMJ45SMVAK1" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-whatsapp" style={{ fontSize: '24px', color: 'green', margin: '0px 5px' }}></i>
                    </a>
                    </div>

                </div>



                <div id="copyright">
                    <hr />
                    <p>Copyright & copy FairArts 2023</p>
                    <p></p>


                    


                </div>
            </footer>
        )
    }
}
export default Footer;