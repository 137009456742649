import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import Bouton from '../component/Bouton'
import { Link } from 'react-router-dom';
import fairArt from '../images/FairArts.png';
import unesco from '../images/sponsors/unesco.jpg'
import brasserie from '../images/sponsors/brasserie.png'
import cerfesac from '../images/sponsors/Cerfesac.png'

const Header = function () {
  const navigate = useNavigate()
  const accueil = useRef(null);
  const navbar = useRef(null);
  const [activeLink, setActiveLink] = useState(accueil.current);
  const [activeURL,setActiveURL] = useState('/')

  function handleLinkOnClick(e) {

    setActiveLink((prevActiveLink) => {
      if (prevActiveLink === e.target) {
        return prevActiveLink
      }
      prevActiveLink.classList.remove('active');
      return e.target
    })
    setActiveURL(window.location.pathname)
  }

  function handleToggleMenu(e) {
    navbar.current.style.display = navbar.current.style.display === 'none' ? 'flex' : 'none';
  }

  useEffect(() => {
    if (activeLink) { activeLink.classList.add('active') }
  }, [activeLink])

  useEffect(() => {
    setActiveLink(accueil.current)
    window.location.pathname.split('/')[1]!='admin'  && navigate(activeURL)

  }, [])

  return (

    <div>

      <nav className="navbar navbar-expand-md navbar-dark bg-dark px-3">
        <img className='img-fluid logo-fairarts mx-3' src={fairArt} alt='' />

        <button onClick={handleToggleMenu} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className='col-md-2'>

        </div>
        <div className='col-md-6 '>
          <div className="collapse navbar-collapse  centered-content" id="navbarNav" ref={navbar}>
            <ul className="navbar-nav ">
              <li className="nav-item li" ref={accueil}>
                <Link to ='/' className='nav-link ' onClick={handleLinkOnClick}>Accueil</Link>
              </li>
              <li className="nav-item li">
                <Link  to="/activites" className='nav-link ' onClick={handleLinkOnClick}>Activités</Link>
              </li>
              <li className="nav-item li">
                <Link to="/actualités" className='nav-link ' onClick={handleLinkOnClick}>Actualités</Link>
              </li>
              <li className="nav-item li">
                <Link to='/talents' className='nav-link ' onClick={handleLinkOnClick}>Meilleurs Talents</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div id='bottom-navbar'>
        <img src={cerfesac} alt='' />
        <img src={brasserie} alt='' />
        <img src={unesco} alt='' />
        <Link to="/AproposDeNous" className='Compartment lien' id='nous'>
          Qui sommes nous?
        </Link>

        <Link to='/' className='Compartment lien' id='NosPartenaires'>
          Nos partenaires
        </Link>
      

      <Link to='/inscription'><Bouton>Inscrivez-Vous</Bouton></Link>

    </div>
    </div >
  )
}
export default Header;


